<template>
  <el-upload
    action=""
    class="avatar-uploader"
    :http-request="uploadSectionFile"
	:on-progress="on_progress"
    :show-file-list="false"
	multiple
   
  >
    <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
    <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
    <i class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
import { updataImg } from "@/api/api.js";
export default {
  props: ['type'],
  data() {
    return {
      imageUrl: "",
    };
  },
  methods: {
    // handleAvatarSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
	on_progress(event, file, fileList){
		console.log(event, file, fileList,'list')
		this.imageUrl = URL.createObjectURL(file.raw);
		// if(event.)
		// this.$emit('addimg',event, file, fileList,'list')
	},
    async uploadSectionFile(files){
      // console.log(this.type,files.file)
      const {data} = await updataImg(files.file, this.type);
	  if(data.code!=200){
		  this.$emit('addimg', data.data)
	  }else{
		  this.$emit('addimg', data.data.imgurl)
	  }
      
    },
  },
};
</script>
<style>
.el-upload {
    margin-left: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

